import React, { useEffect, useState } from "react";
import axios from "axios";
import "./price-list.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { NavLink } from "react-router-dom";
import { FaDownload } from "react-icons/fa6";
import Price from "../../../../assets/files/priceList.pdf";

const CoursePrices = () => {
  const [courses, setCourses] = useState([]);
  const [courseDetails, setCourseDetails] = useState(null);
  const [selectedCourseUid, setSelectedCourseUid] = useState("");
  const [startDate, setStartDate] = useState(new Date());

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await axios.get(
          "https://api.coursepricer.com/courses",
          {
            headers: {
              Authorization:
                "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE3MjYxNjMwMTEsImV4cCI6MjM1NzMxNTAxMSwianRpIjoiM2xibWNQMGozbDBsUjBsSmkzYVZUaSIsInN1YiI6ImFiZHVsbGFoQHByZXN0b25hY2FkZW15b2ZlbmdsaXNoLmNvbSJ9.OI83zQnavKCUVF8fR7xff6hXkRD9I3F3jZEKg7eJ_dk",
            },
          }
        );
        const courseUids = response.data.course_uids || [];

        const detailsPromises = courseUids.map((uid) =>
          axios.get(`https://api.coursepricer.com/courses/${uid}`, {
            headers: {
              Authorization:
                "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE3MjYxNjMwMTEsImV4cCI6MjM1NzMxNTAxMSwianRpIjoiM2xibWNQMGozbDBsUjBsSmkzYVZUaSIsInN1YiI6ImFiZHVsbGFoQHByZXN0b25hY2FkZW15b2ZlbmdsaXNoLmNvbSJ9.OI83zQnavKCUVF8fR7xff6hXkRD9I3F3jZEKg7eJ_dk",
            },
          })
        );

        const detailsResponses = await Promise.all(detailsPromises);
        const details = detailsResponses.map((res) => res.data.data);
        setCourses(details);
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    };

    fetchCourses();
  }, []);

  const fetchCourseDetails = async () => {
    try {
      const response = await axios.get(
        `https://api.coursepricer.com/courses/${selectedCourseUid}`,
        {
          headers: {
            Authorization:
              "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE3MjYxNjMwMTEsImV4cCI6MjM1NzMxNTAxMSwianRpIjoiM2xibWNQMGozbDBsUjBsSmkzYVZUaSIsInN1YiI6ImFiZHVsbGFoQHByZXN0b25hY2FkZW15b2ZlbmdsaXNoLmNvbSJ9.OI83zQnavKCUVF8fR7xff6hXkRD9I3F3jZEKg7eJ_dk",
          },
        }
      );
      setCourseDetails(response.data.data);
    } catch (error) {
      console.error("Error fetching course details:", error);
    }
  };

  return (
    <div className="price-listCont">
      <h1>Select Course and Start Date</h1>
      <div className="price-inputsCont">
        <div className="course-selectCont">
          <label htmlFor="course-select">Select Course:</label>
          <select
            className="select"
            id="course-select"
            value={selectedCourseUid}
            onChange={(e) => setSelectedCourseUid(e.target.value)}
          >
            <option value="">--Select a Course--</option>
            {courses.map((course, index) => (
              <option key={index} value={course.uid}>
                {course.course_name}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label htmlFor="start-date">Select Start Date:</label>
          <div>
            <DatePicker
              className="select"
              id="start-date"
              selected={startDate}
              onChange={(date) => setStartDate(date)}
            />
          </div>
        </div>
        <button className="button" onClick={fetchCourseDetails}>
          Show Course Details
        </button>
      </div>

      {courseDetails && (
        <div className="price-detailCont">
          <h2>{courseDetails.course_name}</h2>
          <p>Cost per week:</p>
          <ul>
            {courseDetails.prices_per_week.map((price, idx) => (
              <li key={idx}>
                Weeks {price.start_week_count} - {price.end_week_count}:{" "}
                <span>${price.cost_per_week}</span>
              </li>
            ))}
          </ul>
          <div>
            <p className="price-ph">
              Description: {courseDetails.course_notes}
            </p>
          </div>

          <div className="pList-btnCont">
            <div className="pList-twoBtn">
              <NavLink className="pList-btn" to="/apply-now">
                Book Course
              </NavLink>

              <NavLink to="/courses" className="pList-btn">
                Learn More
              </NavLink>
            </div>
            <a download="" href={Price} className="pList-btn">
              <FaDownload className="price-icon" />
              Courses Prices
            </a>
          </div>

          {/* Add more course details as needed */}
        </div>
      )}
    </div>
  );
};

export default CoursePrices;
