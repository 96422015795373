import React from "react";
import "./price.css";
import "react-datepicker/dist/react-datepicker.css";
import Header from "../../../components/header/Header";
import Footer from "../../../components/footer/Footer";

import CoursePrices from "./price-list-display/Price-list-display";

// const priceList = [
//   {
//     course: "General English + 6 hours a week",
//     hours: "6 hours a week",
//     price: 84.0,
//   },
//   {
//     course: "General English + 9 hours a week",
//     hours: "9 hours a week",
//     price: 126.0,
//   },
//   {
//     course: "General English + 12 hours a week",
//     hours: "12 hours a week",
//     price: 168.0,
//   },
//   {
//     course: "General English Standard",
//     hours: "15 hours a week",
//     price: 210.0,
//   },
//   {
//     course: "General English Intensive",
//     hours: "21 hours a week",
//     price: 322.0,
//   },
//   {
//     course: "General English + IELTS/Exam Preparation",
//     hours: "21 hours a week",
//     price: 322.0,
//   },
//   {
//     course: "General English Intensive Plus",
//     hours: "25 hours a week",
//     price: 383.33,
//   },
//   { course: "Speaking Skills", hours: "6 hours a week", price: 84.0 },
//   { course: "IELTS/Exam Preparation", hours: "6 hours a week", price: 80.0 },
//   { course: "Private one to one lessons", hours: "Per hour", price: 35.0 },
// ];

const PriceList = () => {
  // useEffect(() => {
  //   document.title = "Price List - Preston Academy of English";
  // }, []);

  // const [selectedCourse, setSelectedCourse] = useState(null);
  // const [startDate, setStartDate] = useState(new Date());
  // const [weeks, setWeeks] = useState(1);
  // const [showDetails, setShowDetails] = useState(false);

  // const handleCourseChange = (event) => {
  //   const course = priceList.find((c) => c.course === event.target.value);
  //   setSelectedCourse(course);
  // };

  // const handleWeeksChange = (event) => {
  //   setWeeks(event.target.value);
  // };

  // const calculateEndDate = (startDate, weeks) => {
  //   const days = weeks * 7;
  //   const allDays = eachDayOfInterval({
  //     start: startDate,
  //     end: addDays(startDate, days),
  //   });
  //   const weekdays = allDays.filter((day) => !isWeekend(day));
  //   return weekdays[weekdays.length - 1];
  // };

  // const calculateTotalPrice = (price, weeks) => {
  //   return price * weeks;
  // };

  // const handleSubmit = () => {
  //   setShowDetails(true);
  // };

  return (
    <>
      <Header />
      <div className="price-cont">
        <h1 className="general-header">Price List</h1>

        <CoursePrices />

        {/* <div className="price-itemCont">
          <div className="each-priceItem">
            <h2>Select Course</h2>
            <select onChange={handleCourseChange}>
              <option value="">Select a course</option>
              {priceList.map((item, index) => (
                <option key={index} value={item.course}>
                  {item.course}
                </option>
              ))}
            </select>
          </div>

          <div className="each-priceItem">
            <h2>Select Start Date</h2>
            <div className="react-datepicker-wrapper">
              <DatePicker
                className="date"
                selected={startDate}
                onChange={(date) => setStartDate(date)}
              />
            </div>
          </div>

          <div className="each-priceItem">
            <h2>Select Duration (weeks)</h2>
            <input
              type="number"
              value={weeks}
              onChange={handleWeeksChange}
              min="1"
            />
          </div>

          <button onClick={handleSubmit}>Show Details</button>
        </div>

        {showDetails && selectedCourse && (
          <div className="course-detailsCont">
            <h3>Selected Course Details</h3>
            <p>
              Price Per Week: <br /> <br />{" "}
              <span className="pWeek">£{selectedCourse.price.toFixed(2)}</span>{" "}
            </p>
            <p>
              Course: <span>{selectedCourse.course}</span>{" "}
            </p>
            <p>
              Hours: <span>{selectedCourse.hours}</span>{" "}
            </p>
            <p>
              Start Date: <span>{startDate.toDateString()}</span>{" "}
            </p>
            <p>
              End Date:{" "}
              <span>{calculateEndDate(startDate, weeks).toDateString()}</span>{" "}
            </p>
            <p>
              Total Price:
              <span className="pWeek">
                £{calculateTotalPrice(selectedCourse.price, weeks).toFixed(2)}
              </span>
            </p>
            <div className="pList-btnCont">
              <div className="pList-twoBtn">
                <NavLink className="pList-btn" to="/apply-stud">
                  Book Course
                </NavLink>

                <NavLink to="/courses" className="pList-btn">
                  Learn More
                </NavLink>
              </div>
              <a download="" href={Price} className="pList-btn">
                <FaDownload />
                Courses Prices
              </a>
            </div>
          </div>
        )} */}
      </div>
      <Footer />
    </>
  );
};

export default PriceList;
